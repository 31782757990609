import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Flex, Text, Icon } from "@chakra-ui/react";
import {
  Dashboard as DashboardIcon,
  Inbox as InboxIcon,
  Logout as LogoutIcon,
  AccountCircle as AccountCircleIcon,
  BusinessOutlined as BusinessOutlinedIcon,
  Security as SecurityIcon,
  Search,
} from "@mui/icons-material";
import { AvatarCustom } from "../../form/avatar.custom";
import { Loading } from "../../shared/Loading";
import { magiclinkService } from "../../../service/magiclink/magiclink.service";
import { RootState, destroyAllState } from "../../../store";
import { ellipsesText } from "../../../util/string.util";
import { Icons } from "../../shared/Icons";
import Tooltip from "../../shared/Tooltip";
import { toggleSidebar as toggleSidebarAction } from "../../../store/slice/Account/user-profile.slice";

import SidebarSearch from "./SidebarSearch";

export const Sidebar: React.FC<any> = (props: any) => {
  const { onToggle, style } = props;

  const dispatch = useDispatch();
  const { photoUri } = useSelector((state: RootState) => state.userProfile);
  const { photoUri: companyPhotoUri } = useSelector(
    (state: RootState) => state.company
  );
  
  const { sidebarCollapsed } = useSelector(
    (state: RootState) => state.userProfile
  );

  const {
    name: companyName,
    isHeadquarters,
    rebateRequired,
  } = useSelector((state: RootState) => state.company);

  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(sidebarCollapsed ? 80 : 260);
  const [sidebarOpen, setSidebarOpen] = useState(!sidebarCollapsed);
  const [searchDropdownOpen, setSearchDropdownOpen] = useState(false);

  const links = [
    {
      route: undefined,
      name: "Search",
      icon: <>🔍</>,
      onClick: () => {
        toggleSidebar();
      },
    },
    { route: "dashboard", name: "Home", icon: <>🏠</> },
    { route: "dropoff", name: "Book a Collection", icon: <>🚛</> },
    { route: "job", name: "Certificates & Reports", icon: <>🔒</> },
    { route: "branch", name: "Branches", icon: <>🏠</> },
  ];

  const getLinks = () => {
    let returnLinks = links;

    if (isHeadquarters) {
      returnLinks = links;
    }

    returnLinks = links.filter((x) => x.route !== "branch");

    if (sidebarOpen) {
      returnLinks = returnLinks.filter((x) => x.name !== "Search");
    }

    return returnLinks;
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    setSidebarWidth(sidebarOpen ? 80 : 260);
    dispatch(toggleSidebarAction());
  };

  const navigate = useNavigate();

  const logout = async () => {
    setLoading(true);
    await magiclinkService
      .logout()
      .then(() => {
        destroyAllState();
      })
      .finally(() => {
        navigate("/");
        setLoading(false);
      });
  };

  return (
    <Box
      w={`${sidebarWidth}px`}
      transition="width 0.3s ease-in-out"
      minH="100%"
      position="fixed"
      zIndex={11}
      onMouseEnter={() => !searchDropdownOpen && setIsHovered(true)}
      onMouseLeave={() => !searchDropdownOpen && setIsHovered(false)}
    >
      <Loading loading={loading} fill ignoreDelay />
      <Flex
        minH="100vh"
        bg="brand.green.200"
        borderRadius="8px"
        border="3px solid white"
        transition="width 0.2s ease-in-out"
        py="24px"
        flexDirection="column"
        justifyContent="space-between"
        position="relative"
        pb={{ base: "100px", md: 0 }}
        {...style}
      >
        <Box px="6px">
          <Flex flexDir="column" gap="6px" alignItems="center"></Flex>

          <SidebarHeader
            sidebarOpen={sidebarOpen}
            onToggle={onToggle}
            toggleSidebar={toggleSidebar}
            photoUri={photoUri}
            companyName={companyName}
            isHovered={isHovered}
          />

          {sidebarOpen && (
            <SidebarSearch
              px="8px"
              mb="18px"
              searchDropdownOpen={searchDropdownOpen}
              setSearchDropdownOpen={setSearchDropdownOpen}
            />
          )}

          {getLinks().map((link, i) => {
            return (
              <LinkStyled
                sidebarOpen={sidebarOpen}
                collapsedTooltip={link.name}
                active={window.location.pathname.startsWith(
                  `/app/${link.route}`
                )}
                key={i}
                onClick={() => {
                  if (link.onClick) {
                    link.onClick();
                    return;
                  }

                  navigate(`/app/${link.route}`);
                  if (onToggle) onToggle(false);
                }}
              >
                <Flex flexDirection="row" gap={2} alignItems={"center"}>
                  <>{link.icon}</>
                  {sidebarOpen && <Text fontSize={"15px"}>{link.name}</Text>}
                </Flex>
              </LinkStyled>
            );
          })}
        </Box>
        <Box>
          <LinkStyled
            sidebarOpen={sidebarOpen}
            collapsedTooltip="Settings"
            active={window.location.pathname.startsWith(`/app/account`)}
            onClick={() => {
              navigate(`/app/account`);
              if (onToggle) onToggle(false);
            }}
          >
            <Flex flexDirection="row" gap={1} alignItems={"center"}>
              {companyPhotoUri ? <AvatarCustom src={companyPhotoUri} widthxheight="30px" /> : <Icon as={AccountCircleIcon}></Icon>}
              {sidebarOpen && (
                <Text fontSize={"15px"}>
                  {isHeadquarters ? "HQ" : "Branch"} Settings
                </Text>
              )}
            </Flex>
          </LinkStyled>

          <LinkStyled
            sidebarOpen={sidebarOpen}
            collapsedTooltip="Logout"
            onClick={logout}
          >
            <Flex flexDirection="row" gap={1} alignItems={"center"}>
              <Icon as={LogoutIcon}></Icon>
              {sidebarOpen && <Text fontSize={"15px"}>Logout</Text>}
            </Flex>
          </LinkStyled>
        </Box>
      </Flex>
    </Box>
  );
};

const LinkStyled: React.FC<any> = (props: any) => {
  const { href, children, active, onClick, sidebarOpen, collapsedTooltip } =
    props;

  const ActionButton = () => {
    return (
      <Button
        onClick={onClick}
        style={{ fontFamily: "zolo-text" }}
        justifyContent={sidebarOpen ? "flex-start" : "center"}
        flexDirection={"row"}
        alignItems="center"
        bg="transparent"
        color={active ? "brand.yellow.200" : "white"}
        borderLeftColor={active ? "brand.yellow.200" : "brand.green.200"}
        mb={{
          lg: "10px",
        }}
        mx={{
          xl: "auto",
        }}
        ps={{
          sm: "10px",
          xl: "12px",
        }}
        _hover={{
          bg: "brand.green.100",
          transition: "background-color 0.3s ease-in-out",
        }}
        py="12px"
        w="100%"
      >
        {children}
      </Button>
    );
  };

  if (sidebarOpen) {
    return <ActionButton />;
  }

  return (
    <Tooltip
      label={collapsedTooltip}
      placement="right"
      ml="5px"
      p="10px"
      borderRadius="6px"
      bg="brand.green.200"
      color="white"
    >
      {ActionButton()}
    </Tooltip>
  );
};

const SidebarHeader = ({
  sidebarOpen,
  onToggle,
  photoUri,
  companyName,
  isHovered,
  toggleSidebar,
}) => {
  const navigate = useNavigate();

  const handleAvatarClick = () => {
    navigate("/app/dashboard");
    if (onToggle) onToggle(false);
  };

  const renderAvatarOrChevron = () => {
    if (isHovered && !sidebarOpen) {
      return (
        <Flex
          cursor="pointer"
          onClick={toggleSidebar}
          borderRadius="6px"
          p="2px"
          _hover={{
            bg: "brand.green.100",
            transition: "background-color 0.3s ease-in-out",
          }}
          style={{
            transition: "opacity 0.3s ease-in-out",
            opacity: isHovered ? 1 : 0,
          }}
        >
          <Icons.ChevronRight fill="white" width={20} height={20} />
        </Flex>
      );
    }

    return (
      <Box
        cursor="pointer"
        borderRadius="50%"
        onClick={handleAvatarClick}
        _hover={{
          transition: "box-shadow 0.2s ease-in-out",
          boxShadow: "0 0 10px #457662",
        }}
      >
        <AvatarCustom src={photoUri} widthxheight="30px" />
      </Box>
    );
  };

  const renderAvatarSection = () => (
    <Flex
      mb={sidebarOpen ? "0" : "26px"}
      flexDir="row"
      alignItems="center"
      gap="6px"
    >
      {renderAvatarOrChevron()}
      {sidebarOpen && (
        <Box
          color="white"
          fontSize="20px"
          fontWeight="bold"
          style={{
            transition: "opacity 0.3s ease-in-out",
            opacity: sidebarOpen ? 1 : 0,
          }}
        >
          {ellipsesText(companyName, 20)}
        </Box>
      )}
    </Flex>
  );

  const renderToggleButton = () => (
    <Box
      style={{
        transition: "opacity 0.3s ease-in-out",
        opacity: isHovered ? 1 : 0,
      }}
    >
      {isHovered && sidebarOpen && (
        <Tooltip label={sidebarOpen ? "Collapse" : "Expand"} placement="right">
          <Flex
            cursor="pointer"
            onClick={toggleSidebar}
            borderRadius="6px"
            p="2px"
            _hover={{
              bg: "brand.green.100",
              transition: "background-color 0.3s ease-in-out",
            }}
            display={{ base: "none", md: "block" }}
          >
            {sidebarOpen ? (
              <Icons.ChevronLeft fill="white" width={20} height={20} />
            ) : (
              <Icons.ChevronRight fill="white" width={20} height={20} />
            )}
          </Flex>
        </Tooltip>
      )}
    </Box>
  );

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent={sidebarOpen ? "space-between" : "center"}
      width="100%"
      p={{ base: "0px", md: "10px" }}
      mb={7}
      mt={3}
      h="30px"
    >
      {renderAvatarSection()}
      {renderToggleButton()}
    </Flex>
  );
};
