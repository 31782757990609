import { Button } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { Size } from "../../../models/enum/size.enum";
import { LoadingComponent } from "../../shared/Loading";

export const ButtonCustom: React.FC<Props> = (props: Props) => {
  const getWidth = () => {
    switch (props.size) {
      case Size.small:
        return "80px";
      case Size.medium:
        return "120px";
      case Size.large:
        return "160px";
      //...
      default:
        return "hug";
    }
  };

  let properties = {
    hidden: props.hidden,
    isDisabled: props.disabled,
    type: props.type,
    variant: "container",
    onClick: props.onClick,
    width: getWidth(),
    borderRadius: props.rounded ? "100px" : "8px",
    style: { ...props.style },
    color: "brand.green.300",
    backgroundColor: "brand.yellow.200",
    ...props,
  } as any;

  const children = props.loading ? (
    <LoadingComponent w={4} h={4} thickness="2px" color="brand.green" />
  ) : (
    props.children
  );
  
  if (props.danger) {
    return <DangerButton {...properties}>{children}</DangerButton>;
  }

  if (props.neutral) {
    return <NeutralButton {...properties}>{children}</NeutralButton>;
  }

  if (props.secondary) {
    return <SecondaryButton {...properties}>{children}</SecondaryButton>;
  }

  return <ButtonComponent {...properties}>{children}</ButtonComponent>;
};

const StyledButton = styled(Button)({
  boxShadow: "none !important",
  fontSize: "14px",
  fontWeight: 600,
  border: "none",
  padding: "0 10px",
});

const ButtonComponent = styled(StyledButton)({});

const SecondaryButton = styled(StyledButton)({
  backgroundColor: "#4F46E5",
  color: "white",
});

const NeutralButton = styled(StyledButton)({
  backgroundColor: "transparent",
  border: "1px solid #D4D4D8",
});

const DangerButton = styled(StyledButton)({
  backgroundColor: "#FEE2E2",
  border: "none",
  color: "#B91C1C",
});

class Props {
  children: any;
  onClick?: () => void;
  size?: Size;
  rounded?: boolean;
  secondary?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  neutral?: boolean;
  danger?: boolean;
  loading?: boolean;
  hidden?: boolean;
  style?: any; // Pass in custom styles. i.e. <ButtonCustom style={{ width: "100%" }}>Test BTN</ButtonCustom>
  // kvp for misc props
  [key: string]: any;
}
