import { BaseService } from "../base.service";

export class AdminCompanyService extends BaseService {
  constructor() {
    super();
  }

  async updateCompany(data: { name; url; country }) {
    await this.client.put(`/admin/company`, data);
  }

  async updateLogo(data: any) {
    await this.client.post(`/admin/company/media`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export const adminCompanyService = new AdminCompanyService();