import { Box, Flex } from "@chakra-ui/react";
import { FlexCol } from "../../../../components/shared/FlexCol";
import { FlexRow } from "../../../../components/shared/FlexRow";
import { useEffect, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { ellipsesText } from "../../../../util/string.util";

export const JobDetailAttachment = ({ job }) => {
    const [attachments, setAttachments] = useState(job.attachments ?? []);
  
    useEffect(() => {
      setAttachments(job.attachments);
    }, [job.attachments]);
  
    return (
        <FlexCol
          h="fit-content"
          textAlign="right"
          alignItems="flex-end"
          maxW="400px"
          minW="150px"
        >
          <FlexRow mb={2}>
            <Box>Attachments - ({attachments?.length ?? 0}) </Box>
            <AttachFileIcon style={{ width: "16px" }} />
          </FlexRow>
  
          {attachments?.map((attachment, index) => (
            <Flex
              key={index}
              cursor="pointer"
              fontWeight="500"
              alignItems="center"
              gap={1}
            >
              <Box
                fontSize="10px"
                fontWeight="500"
                color="white"
                bg="gray"
                borderRadius="4px"
                px={2}
                h="fit-content"
              >
                {attachment.mediaContext}
              </Box>
  
              <Box
                textDecor="underline"
                color="blue"
                fontSize="14px"
                w="160px"
                onClick={() => window.open(attachment.fileUri, "_blank")}
              >
                {ellipsesText(attachment.name, 22)}
              </Box>
            </Flex>
          ))}
        </FlexCol>
    );
  };
  