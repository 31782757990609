import {
  Box,
  TableContainer as ChakraTableContainer,
  Table as ChakraTable,
  Th,
  Thead,
  Tr,
  Flex,
  Image,
} from "@chakra-ui/react";

export const TableContainer = ({
  columns,
  children,
  sortColumn = null,
  onSort = null,
  isEmpty = undefined,
  emptyChildren = undefined,
  ...props
}) => {
  const handleSort = (accessor: string) => {
    const sort = {
      accessor,
      direction:
        sortColumn?.accessor === accessor ? sortColumn?.direction ^ 1 : 0,
    };

    if (onSort) onSort(sort);
  };

  return !isEmpty ? (
    <Box
      {...props}
      style={{
        boxShadow: "0 0 10px 1px #ECECEC",
      }}
      borderRadius="6px"
      overflowX="hidden"
      h="fit-content"
    >
      <ChakraTable
        size="sm"
        variant="simple"
        style={{ tableLayout: "fixed", width: "100%", height: "1px" }}
      >
        <Thead>
          <Tr>
            {columns.map((column, i) => (
              <Th
                width={column.width ?? "full"}
                cursor={column.disableSort ? undefined : "pointer"}
                onClick={
                  column.disableSort
                    ? undefined
                    : () => handleSort(column.accessor)
                }
                color="brand.green"
                key={i}
                style={{
                  position: "sticky",
                  top: "0",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  zIndex: 1,
                  backgroundColor: "#FBFBF8",
                }}
              >
                <Flex
                  width={column.width}
                  flexDir="row"
                  justifyContent="space-between"
                >
                  {column.header}
                  {column.accessor === sortColumn?.accessor && (
                    <Box color="brand.green">
                      {sortColumn?.direction === 0 ? "▲" : "▼"}
                    </Box>
                  )}
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        {children}
      </ChakraTable>
    </Box>
  ) : emptyChildren ? (
    <>{emptyChildren}</>
  ) : (
    <Flex
      h="40vh"
      flexDir="column"
      justifyContent={"center"}
      alignItems="center"
    >
      <Image
        src={`/assets/table/earth.png`}
        alt="upload"
        width="250px"
        // height="75px"
        mr="6px"
      />
      <Box color="brand.gray.400" fontFamily="zolo-header">
        {"Nothing here... yet."}
      </Box>
    </Flex>
  );
};
