import { Box, Flex, VStack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Loading } from "../../shared/Loading";
import { companyService } from "../../../service/company/company.service";
import { FormInput } from "../../form/controls/form.input";
import { useOutsideClick } from "../../hooks/click-outside.hook";
import { useNavigate, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";

const SidebarSearch = ({
  searchDropdownOpen,
  setSearchDropdownOpen,
  ...props
}) => {
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [globalSearchResults, setGlobalSearchResults] = useState([]);
  const [inventorySearchResults, setInventorySearchResults] = useState([]);
  const [branchSearchResults, setBranchSearchResults] = useState([]);
  const [jobSearchResults, setJobSearchResults] = useState([]);
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState("");

  const ENTITY_COLORS = {
    job: "#8BBFEA",
    inventory: "#A0CCA2",
    branch: "#F6D981",
  };

  useOutsideClick(wrapperRef, () => setSearchDropdownOpen(false));

  useEffect(() => {
    const term = searchParams.get("searchTerm");
    if (term) setSearchTerm(term);
  }, [searchParams]);

  const handleGlobalSearch = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setLoading(true);

    try {
      const res = await companyService.globalSearch(value);
      const results = res.data;
      setBranchSearchResults(results.filter((item) => item.type === "branch"));
      setInventorySearchResults(
        results.filter((item) => item.type === "inventory")
      );
      setJobSearchResults(results.filter((item) => item.type === "job"));
      setGlobalSearchResults(results);
    } catch (error) {
      console.error("Error during search:", error);
    } finally {
      setLoading(false);
      setSearchDropdownOpen(true);
    }
  };

  const renderSearchResults = (results, type, label, navigateTo) => {
    if (!results.length) return null;

    return (
      <>
        {results.length === 5 && (
          <Flex cursor="pointer" fontSize="12px" justifyContent="flex-end">
            Top 5 showing
          </Flex>
        )}
        {results.map((item, index) => (
          <Flex
            key={item.id}
            py="1"
            px="2"
            flexDir="row"
            justifyContent="space-between"
            borderBottom={
              index === results.length - 1 ? "1px solid #E2E8F0" : ""
            }
            _hover={{ cursor: "pointer", bg: "gray.200" }}
            borderRadius="4px"
            onClick={() => {
              navigateTo(item);
              setSearchDropdownOpen(false);
            }}
          >
            <Box>{type === "job" ? `JOB-${item.title}` : item.title}</Box>
            <Box
              fontSize="12px"
              fontWeight="600"
              h="20px"
              px="12px"
              bg={ENTITY_COLORS[type]}
              borderRadius="6px"
            >
              {label}
            </Box>
          </Flex>
        ))}
      </>
    );
  };

  const openBranchTab = (item) => {
    navigate(`/app/branch/${item.id}?searchTerm=${searchTerm}`);
  };

  const openJobTab = (item) => {
    navigate(`/app/job/${item.id}?searchTerm=${searchTerm}`);
  };

  const openInventoryTab = (item) => {
    navigate(
      `/app/job/${item.job_id}?inventory_id=${item.id}&searchTerm=${searchTerm}`
    );
  };

  const MotionVStack = motion(VStack);

  return (
    <Flex
      ref={wrapperRef}
      flexDir="row"
      alignItems="center"
      w="100%"
      {...props}
    >
      <Flex w="100%" flexDir="row" gap="6px" justifyContent="center">
        <Box w="100%" pos="relative" zIndex={5}>
          <FormInput
            title=""
            name="globalSearch"
            value={searchTerm}
            placeholder="Looking for something?"
            onChange={handleGlobalSearch}
            onFocus={handleGlobalSearch}
            autoComplete="off"
          />
          <MotionVStack
            initial={{ opacity: 0, y: -10 }}
            animate={
              searchDropdownOpen ? { opacity: 1, y: 0 } : { opacity: 0, y: -10 }
            }
            transition={{ duration: 0.3 }}
            pos="absolute"
            w="130%"
            h={searchDropdownOpen ? "auto" : "0"}
            align="stretch"
            mt="2"
            zIndex={5}
            boxShadow="xl"
            border="1px solid #E2E8F0"
            borderRadius="md"
            bg="white"
            px="3"
            py="4"
            pointerEvents={searchDropdownOpen ? "auto" : "none"}
          >
            {globalSearchResults.length > 0
              ? [
                  renderSearchResults(
                    branchSearchResults,
                    "branch",
                    "Branch",
                    openBranchTab
                  ),
                  renderSearchResults(
                    jobSearchResults,
                    "job",
                    "Job",
                    openJobTab
                  ),
                  renderSearchResults(
                    inventorySearchResults,
                    "inventory",
                    "Inventory",
                    openInventoryTab
                  ),
                ]
              : "Nothing to see"}
          </MotionVStack>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SidebarSearch;
