import { UserProfile } from "../../models/user-profile.model";
import { UserModel } from "../../models/user.model";
import { BaseService } from "../base.service";

export class UserService extends BaseService {
  constructor() {
    super();
  }

  async update(data: { firstName; lastName; mobile }) {
    await this.client.put(`/user`, data);
  }

  async get(): Promise<UserModel> {
    const resp = await this.client.get(`/user`);
    return resp.data;
  }

  async profileComplete(): Promise<{ profileComplete; companyComplete }> {
    const resp = await this.client.get(`/user/profile/complete`);
    return resp.data;
  }

  async verifyRegistrationToken(email: string, token: string) {
    await this.client.post(`/user/token/verify`, { email, token });
  }

  async register(
    body: { email; firstName; lastName; regToken },
    didToken: string
  ): Promise<UserProfile> {
    const resp = await this.client.post(`/user/register`, body, {
      headers: { Authorization: `Bearer ${didToken}` },
    });

    return resp.data;
  }

  async updateProfilePhoto(formData: any) {
    const resp = await this.client.post(`/user/media/profile/photo`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return resp.data;
  }
}

export const userService = new UserService();
