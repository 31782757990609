import { FlexRow } from "../../../shared/FlexRow";
import { formatNumber } from "../../../../util/number.util";
import { BaseCard } from "../../../form/base.container";
import { FlexCol } from "../../../shared/FlexCol";
import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { jobService } from "../../../../service/job/job.service";
import { InventoryMetricModel } from "../../../../models/inventory-metric.model";
import { inventoryService } from "../../../../service/inventory/inventory.service";

export const JobDetailMetrics = ({ job }) => {
  const [metrics, setMetrics] = useState<InventoryMetricModel>(null);

  const init = async () => {
    await inventoryService.getMetrics(job?.id).then((metrics) => {
      setMetrics(metrics);
    });
  };

  useEffect(() => {
    if (job) {
      init();
    }
  }, [job]);

  return (
    <FlexRow mt="26px">
      <CircularEconomyMetrics
        reuseInventory={metrics?.totalReuse}
        recyclingInventory={metrics?.totalRecycle}
        subtitle="Recovered Devices"
      />

      <SustainabilityMetrics
        reuseWeight={metrics?.reuseWeight}
        recycleWeight={metrics?.recycleWeight}
        subtitle="E-Waste Diverted from Landfill"
      />

      <SocialImpactMetrics totalImpact={metrics?.totalImpact ?? 0} />
    </FlexRow>
  );
};

const CircularEconomyMetrics = ({ reuseInventory, recyclingInventory, subtitle }) => {
  const data = [
    { label: "Re-use", value: reuseInventory },
    { label: "Recycle", value: recyclingInventory },
  ];

  return <MetricsCard title="🌀 Circular Economy" data={data} subtitle={subtitle} />;
};

const SustainabilityMetrics = ({ reuseWeight, recycleWeight, subtitle }) => {
  const data = [
    {
      label: "Re-use",
      value: reuseWeight,
    },
    {
      label: "Recycle",
      value: recycleWeight,
    },
  ];

  return (
    <MetricsCard
      title="♻️ Sustainability Impact"
      data={data}
      unit="kg"
      formatTotal={(total) => total.toFixed(2)}
      subtitle={subtitle}
    />
  );
};

const SocialImpactMetrics = ({ totalImpact }: { totalImpact: number }) => {
  return (
    <BaseCard
      boxShadow="lg"
      maxW={{ base: "100%", md: "155px" }}
      h={{ base: "100%", md: "140px" }}
      p={{ base: "6px", md: "12px" }}
      fontSize="14px"
      textAlign="center"
    >
      <FlexCol>
        <FlexCol gap="0">
          <div className="text-[16px] text-black">⚡️ Social Impact</div>
          <div className="text-[12px] font-normal">Devices Donated</div>
        </FlexCol>

        <Box
          fontSize="28px"
          mb="12px"
          fontWeight="bold"
          color="brand.green.200"
          fontFamily="zolo-header"
        >
          {formatNumber(totalImpact)}
        </Box>
      </FlexCol>
    </BaseCard>
  );
};

const MetricsCard = ({
  title,
  subtitle,
  data,
  unit = "",
  formatTotal = (total) => total,
}: {
  title: string;
  subtitle?: string;
  data: { label: string; value: number }[];
  unit?: string;
  formatTotal?: (total: number) => string | number;
}) => {
  const totalValue = data.reduce((acc, item) => acc + item.value, 0);

  return (
    <BaseCard
      boxShadow="lg"
      maxW="215px"
      h={{ base: "100%", md: "140px" }}
      p={{ base: "6px", md: "12px" }}
      fontSize="14px"
    >
      <FlexCol>
        <FlexCol gap="0">
          <div className="text-[16px] text-black">{title}</div>
          <div className="text-[12px] font-normal">{subtitle}</div>
        </FlexCol>

        {data.map((item, index) => (
          <FlexRow key={index} justifyContent="space-between">
            <div>{item.label}:</div>
            <div>
              {formatNumber((item.value ?? 0).toFixed(2))} {unit}
            </div>
          </FlexRow>
        ))}

        <FlexRow justifyContent="space-between" alignItems="center">
          <Box fontWeight="bold" color="brand.green.200">
            Total
          </Box>
          <Box
            fontWeight="bold"
            color="brand.green.200"
            fontFamily="zolo-header"
          >
            {formatTotal(totalValue)} {unit}
          </Box>
        </FlexRow>
      </FlexCol>
    </BaseCard>
  );
};
