import { Flex, Image, useDisclosure } from "@chakra-ui/react";
import { CustomProfileCard } from "../../components/app/account/profile/AccountProfileCard";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { AccountUserCard } from "../../components/app/account/user/AccountUserCard";
import { Role } from "../../models/enum/role.enum";
import AccountHeader from "../../components/app/account/account-header";
import { ButtonCustom } from "../../components/form/controls/form.button";
import { AddIcon } from "@chakra-ui/icons";
import { InviteUserModal } from "../../components/app/account/inviteUser.modal";

export const Account = ({}) => {
  const { role, sidebarCollapsed } = useSelector(
    (state: RootState) => state.userProfile
  );

  return (
    <>
      <Flex flexDir="row" w="100%" justifyContent="center">
        <Flex gap="24px" pt="30px">
          <Flex pos="relative" direction="column" gap="24px">
            <Image
              pos="absolute"
              src="/assets/account-bg.png"
              w="100%"
              borderTopRadius="8px"
            />

            <CustomProfileCard />
            {role === Role.ADMIN && <AccountUserCard />}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const InviteUserButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { role, email } = useSelector((state: RootState) => state.userProfile);

  return (
    <>
      <ButtonCustom
        hidden={role !== Role.ADMIN}
        onClick={onOpen}
        neutral
        style={{
          width: "120px",
          backgroundColor: "transparent",
        }}
      >
        <Flex flexDir="row" gap="4px" alignItems="center">
          <AddIcon fontSize="12px" />
          Invite user
        </Flex>
      </ButtonCustom>
      <InviteUserModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={() => {
          onClose();
        }}
      />
    </>
  );
};
