import Autocomplete from "react-google-autocomplete";
import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "@chakra-ui/react";
import { formatGoogleLocation } from "../../../util/location.util";
import { FormTitle } from "../form.title";

export const GooglePlacesDropdown: React.FC<{
  name?: string;
  title?: string;
  required?: boolean;
  onPlaceSelected: (selected: any) => void;
  onChange?: (val: any) => void;
  isInvalid?: boolean;
  invalidInputMessage?: string;
  placeholder?: string;
  defaultValue?: string;
}> = (props) => {
  const inputField = () => {
    const handleChange = (e: any) => {
      return props.onChange(e);
    };

    const handlePlaceChange = (e: any) => {
      const location = formatGoogleLocation(e);
      const obj = {
        ...e,
        target: {
          name: props.name ?? "location",
          value: location,
        },
      };

      props.onPlaceSelected(obj);
    };

    return (
      <Autocomplete
        style={{
          padding: "8px 12px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          boxShadow: "none",
          border: "1px solid #D5D3D9",
          borderRadius: "6px",
          height: "40px",
        }}
        placeholder={props.placeholder}
        options={{
          types: [], // This line restricts the results to addresses only
          componentRestrictions: { country: ["au", "ph", "cn"] }, // This line restricts the results to Australia only
        }}
        defaultValue={props.defaultValue}
        apiKey={
          "AIzaSyAynPv83lZEG8JskOGKsCgM0KQ-S5nu62M&callback=Function.prototype" // add callback param to remove error https://github.com/ErrorPro/react-google-autocomplete/issues/200
        }
        onPlaceSelected={handlePlaceChange}
        onChange={handleChange}
      />
    );
  };

  return (
    <>
      <Box>
        <FormTitle required={props.required}>
          {props.title}
        </FormTitle>
        {inputField()}

        {!!props.isInvalid ? (
          <ErrorMessage>{props.invalidInputMessage}</ErrorMessage>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default GooglePlacesDropdown;

const Label = styled(Text)({
  fontWeight: "600",
  size: "15px",
});

const ErrorMessage = styled("div")({
  color: "#dc3545",
});
