import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Box, Flex, Text } from "@chakra-ui/react";
import { convertIsoTime } from "../../../util/date.util";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import PopoverCustom from "../../shared/PopoverCustom";
import { FlexRow } from "../../shared/FlexRow";
import { FlexCol } from "../../shared/FlexCol";
import { TermsAndConditions } from "../../shared/TermsConditions";
import { JobDetailAttachment } from "./parts/job-detail-attachment";
import { JobDetailNotes } from "./parts/job-detail-notes";
import { JobDetailMetrics } from "./parts/job-detail-metrics";

export const JobDetailContent = ({ job, inventory }) => {

  return (
    <>
      <Flex
        pos="relative"
        flexDir="column"
        gap="4px"
        justifyContent="space-between"
        w="full"
      >
        <Flex fontWeight="600" color="gray" flexDir="row" gap="8px" w="full">
          <FlexCol>
            <FlexRow between w="full">
              <FlexRow gap="4px">
                📍 {job.companyAddressName}
              </FlexRow>
            </FlexRow>

            <FlexRow between>
              <FlexRow gap="4px">
                🗓️ Collection Date: {convertIsoTime(job.startDate)}
              </FlexRow>
            </FlexRow>

            <Box w="fit-content">
              <FlexRow
                link
                onClick={() =>
                  window.open(
                    `/app/company/${job.companyId}`,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              >
                🤝🏼 <Text>{job.companyName}</Text>
                {/* <SwitchAccessShortcutIcon /> */}
              </FlexRow>
            </Box>

            <JobDetailMetrics job={job} inventory={inventory} />
          </FlexCol>

          <Flex flexDir="column" alignItems="end">
            <JobDetailAttachment job={job} />
            <JobDetailNotes job={job} />
            <DisclaimerSection />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const DisclaimerSection = () => {
  return (
    <Flex>
      <PopoverCustom
        width="500px"
        trigger={
          <Box
            maxWidth="200px"
            display="flex"
            alignItems="center"
            height="80%"
            px={3}
            py={1}
            _hover={{
              bgColor: "gray.200",
            }}
            rounded={{ base: "md", md: "xl" }}
            cursor="pointer"
            overflow="hidden"
          >
            <Box
              fontWeight="500"
              maxWidth="100%"
              flex="1"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              Click for the Nitty Gritty
            </Box>
          </Box>
        }
      >
        <TermsAndConditions fontWeight="500" textAlign="left" alignItems="flex-start" mb="16px" />
      </PopoverCustom>
    </Flex>
  );
};
