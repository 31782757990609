import { useContext, useEffect, useState } from "react";
import {
  Box,
  Center,
  Flex,
  Image,
  useDisclosure,
  SlideFade,
  Text,
  Grid,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { jobService } from "../../service/job/job.service";
import { convertIsoTime, getDayPeriod } from "../../util/date.util";
import { PageTitle } from "../../components/shared/PageTitle";
import { useSelector } from "react-redux";
import { JobProgress } from "../../components/app/dashboard/JobProgress";
import { companyService } from "../../service/company/company.service";
import { DashboardModel } from "../../models/dashboard.model";
import { ButtonCustom } from "../../components/form/controls/form.button";
import { ForestBackground } from "../../components/shared/ForestBackground";
import { dropoffService } from "../../service/company/dropoff.service";
import { AppContext } from "../../App";
import { inventoryService } from "../../service/inventory/inventory.service";
import { FlexCol } from "../../components/shared/FlexCol";
import { InventoryMetricModel } from "../../models/inventory-metric.model";
import { BaseCard } from "../../components/form/base.container";
import PieChartComponent from "../../components/shared/chart/PieChart";
import { PieChartDataItem } from "../../components/shared/chart/PieChart";
import { formatNumber } from "../../util/number.util";
import { TermsAndConditions } from "../../components/shared/TermsConditions";

export const Dashboard = ({}) => {
  const { displayName } = useSelector((state: any) => state.userProfile);
  const { isOpen, onOpen } = useDisclosure();

  const [dropoffs, setDropoffs] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [jobs, setJobs] = useState([] as any[]);
  const [completeJobs, setCompleteJobs] = useState([] as any[]);
  const [metrics, setMetrics] = useState<InventoryMetricModel>(null);

  const init = async () => {
    setLoading(true);
    await jobService
      .getList()
      .then((jobs) => {
        const data = jobs.map((job) => {
          return {
            ...job,
            startDate: convertIsoTime(job.startDate),
          };
        });

        setCompleteJobs(data.filter((x) => x.status.toString() === "COMPLETE"));
        setJobs(data.filter((x) => x.status.toString() !== "COMPLETE"));
      })
      .finally(() => {
        setLoading(false);
      });

    await dropoffService.getList().then((dropoffs) => {
      const activeDropoffs = dropoffs.filter(
        (dropoff) => dropoff.status.toString() !== "PROCESSED"
      );
      setDropoffs(activeDropoffs);
    });
  };

  const fetchInventoryMetrics = async () => {
    await inventoryService.getMetrics().then((metrics) => {
      setMetrics(metrics);
    });
  };

  useEffect(() => {
    init();
    fetchInventoryMetrics();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      onOpen();
    }, 500);

    return () => clearTimeout(timer);
  }, [onOpen]);

  return (
    <>
      <ForestBackground />
      <SlideFade in={isOpen} offsetY="-20px">
        <Center>
          <Box
            zIndex={1}
            textAlign="center"
            color={getDayPeriod() === "evening" ? "white" : "brand.green.300"}
            fontWeight="bold"
            fontSize={{ base: "36px", md: "42px" }}
            fontFamily="zolo-header"
            mt={{ base: "42px", md: "12px" }}
            mb="52px"
          >
            {getDayPeriod() === "evening" ? "✨" : "☀️"} Good {getDayPeriod()},{" "}
            {displayName}.
          </Box>
        </Center>
      </SlideFade>

      <Flex zIndex={1} flexDir="column" alignItems="center" w="100%">
        <CardsSection metrics={metrics} />

        <Box w="100%" maxW="1150px">
          <InventoryCard metrics={metrics} />

          <ProgressSection
            jobs={jobs}
            dropoffs={dropoffs}
            completeJobs={completeJobs}
            onToggle={(value) => setToggled(value)}
            toggled={toggled}
          />
        </Box>
      </Flex>
    </>
  );
};

const CardsSection = ({ metrics }: { metrics: InventoryMetricModel }) => {
  const [dashboard, setDashboard] = useState({} as DashboardModel);
  const { currencySymbol } = useContext(AppContext);
  const { rebateRequired } = useSelector((state: any) => state.company);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  });

  // Individual state values for each card's visibility
  const [showWeightCard, setShowWeightCard] = useState(false);
  const [showEmissionCard, setShowEmissionCard] = useState(false);
  const [showCountCard, setShowCountCard] = useState(false);
  const [showCreditsCard, setShowCreditsCard] = useState(false);
  const [showInventoryCard, setShowInventoryCard] = useState(false);

  const init = async () => {
    const dashboard = await companyService.getDashboard();
    setDashboard(dashboard);

    // Staggered timeouts for each card's animation
    setTimeout(() => setShowWeightCard(true), 1000);
    setTimeout(() => setShowEmissionCard(true), 1200);
    setTimeout(() => setShowCountCard(true), 1400);
    setTimeout(() => setShowCreditsCard(true), 1600);
    setTimeout(() => setShowInventoryCard(true), 1800);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Flex
        direction={isMobile ? "column" : "row"}
        wrap={isMobile ? "wrap" : "nowrap"}
        justifyContent="center"
        alignItems="center"
        gap={isMobile ? "12px" : "28px"}
        rowGap="12px"
        p={isMobile ? "0px" : "16px"}
        py="48px"
        // If isMobile is true, create a grid with 2 columns, else use default styles
        sx={{
          gridTemplateColumns: isMobile ? "repeat(2, 1fr)" : "none",
          display: isMobile ? "grid" : "flex",
        }}
        width="full"
      >
        <SlideFade in={showCountCard} offsetY="-20px">
          <DashboardCard
            title="Circular Economy"
            type="circular_economy"
            value={`${formatNumber(dashboard.totalInventory ?? 0)}`}
            text={<Box>Recovered Devices</Box>}
          />
        </SlideFade>
        <SlideFade in={showWeightCard} offsetY="-20px">
          <DashboardCard
            title="Sustainability Impact"
            type="sustainability_impact"
            value={`${formatNumber(metrics?.totalWeight ?? 0)} kg`}
            text="E-Waste Diverted from Landfill"
          />
        </SlideFade>

        {!!(metrics?.totalImpact ?? 0) && (
          <SlideFade in={showEmissionCard} offsetY="-20px">
            <DashboardCard
              title="Social Impact"
              type="social_impact"
              value={`${formatNumber(metrics?.totalImpact ?? 0)}`}
              text={<Box>Devices Donated</Box>}
            />
          </SlideFade>
        )}

        {rebateRequired && (
          <SlideFade in={showCreditsCard} offsetY="-20px">
            <DashboardCard
              title="Rebates"
              type="rebates"
              value={`${currencySymbol}${formatNumber(dashboard.credits ?? 0)}`}
              text={
                <Box>
                  <Text>Value Back from</Text> E-Waste
                </Box>
              }
            />
          </SlideFade>
        )}
      </Flex>

      {/* <SlideFade in={showCreditsCard} offsetY="-20px">
        <InventoryCard metrics={metrics} />
      </SlideFade> */}
    </>
  );
};

const ProgressSection = ({
  jobs,
  completeJobs,
  dropoffs,
  toggled,
  onToggle,
}) => {
  const [showLabel, setShowLabel] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const navigate = useNavigate();

  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
    });

    useEffect(() => {
      const handleResize = () => {
        setWindowSize({ width: window.innerWidth });
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  };

  const { width } = useWindowSize();
  const isMobile = width < 768;

  const init = async () => {
    setTimeout(() => setShowLabel(true), 2000);
    setTimeout(() => setShowProgress(true), 2400);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Flex my="16px" flexDirection="column">
      <SlideFade in={showLabel} offsetY="-20px">
        <Flex
          my="13px"
          mb="26px"
          flexDirection="row"
          gap="16px"
          justifyContent="space-between"
        >
          {isMobile ? (
            <PageTitle
              textTransform="none"
              fontSize="24px"
              color={getDayPeriod() === "evening" ? "white" : undefined}
            >
              Pick-ups
            </PageTitle>
          ) : (
            <PageTitle
              textTransform="none"
              fontSize="26px"
              color={getDayPeriod() === "evening" ? "white" : undefined}
            >
              Requested pick-ups
            </PageTitle>
          )}

          {/* <ToggleSwitch
            value={toggled}
            onChange={onToggle}
            labels={{
              left: "In progress",
              right: "Complete",
            }}
            w="300px"
            mb="16px"
          /> */}

          <ButtonCustom w="120px" onClick={() => navigate("/app/dropoff")}>
            View all
          </ButtonCustom>
        </Flex>
      </SlideFade>

      <SlideFade in={showProgress} offsetY="-40px">
        <Flex flexDirection="column" gap="6px" w="100%">
          {!!(toggled ? completeJobs : jobs)?.length ||
          (!toggled && !!dropoffs?.length) ? (
            <>
              {!toggled &&
                dropoffs
                  ?.slice(0, 3)
                  ?.map((dropoff, i) => (
                    <JobProgressPlaceholder
                      key={`dropoff-${i}`}
                      date={dropoff.requestDate}
                    />
                  ))}

              {/* {(toggled ? completeJobs : jobs).map((job, i) => (
                <JobProgress
                  defaultOpen={i === 0}
                  key={`job-${job.id}`}
                  job={job}
                />
              ))} */}
            </>
          ) : (
            <EmptyProgress />
          )}
        </Flex>
      </SlideFade>
    </Flex>
  );
};

const EmptyProgress = () => {
  const navigate = useNavigate();

  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      p="24px"
      gap="24px"
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.2)", // adjust color and transparency here
        backdropFilter: "blur(10px)", // adjust blur radius here
      }}
      borderRadius="16px"
    >
      <Image src="/assets/dashboard/icons/recycle.svg" w="260px" h="260px" />
      <Box fontSize="24px" fontWeight="bold" color="brand.green.300">
        Turn your old tech into impact.
      </Box>
      <Box color="brand.green.300">
        Create a new pick-up request and track progress here.
      </Box>
      <ButtonCustom onClick={() => navigate("/app/dropoff")}>
        New pick-up request
      </ButtonCustom>
    </Flex>
  );
};

const DashboardCard = ({
  title = undefined,
  type = undefined,
  value,
  text,
  iconStyle = undefined,
  ...props
}) => {
  const styles = { fontSize: "56px" };

  const Icon = () => {
    switch (type) {
      case "circular_economy":
        return <>🌀</>;
      case "sustainability_impact":
        return <>♻️</>;
      case "social_impact":
        return <>⚡️</>;
      case "rebates":
        return <>💰</>;
      default:
        return <></>;
    }
  };

  return (
    <Flex
      boxShadow="0px 8px 16px 0px #00000033"
      pos="relative"
      flexDir="column"
      bg="white"
      border="1px solid lightGray"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      h={{ base: "200px", md: "240px" }}
      w={{ base: "min-200", md: "220px" }}
      borderRadius="10px"
      {...props}
    >
      <FlexCol alignItems="center" justifyContent="center">
        <FlexCol
          alignItems="center"
          justifyContent="center"
          gap={{ base: "0", md: "16px" }}
        >
          <Box fontSize="48px">{Icon()}</Box>
          <Box
            mb="18px"
            fontSize="28px"
            fontWeight="bold"
            color="brand.green.200"
            fontFamily="zolo-header"
          >
            {value}
          </Box>
        </FlexCol>

        <FlexCol
          h={{ base: "140px", md: "100px" }}
          alignItems="center"
          justifyContent="start"
        >
          {title && (
            <Box
              fontSize={{ base: "14px", md: "16px" }}
              fontWeight="bold"
              color="brand.green.200"
              fontFamily="zolo-header"
            >
              {title}
            </Box>
          )}
          <Box color="brand.gray.400" fontSize="14px" fontWeight="500">
            {text}
          </Box>
        </FlexCol>
      </FlexCol>
    </Flex>
  );
};

const InventoryCard = ({ metrics }: { metrics: InventoryMetricModel }) => {
  const [sustainabilityImpact, setSustainabilityImpact] = useState(
    [] as PieChartDataItem[]
  );

  const [circularEconomy, setCircularEconomy] = useState(
    [] as PieChartDataItem[]
  );

  const [ewasteBreakdown, setEwasteBreakdown] = useState(
    [] as PieChartDataItem[]
  );

  const [ewasteBreakdownWeight, setEwasteBreakdownWeight] = useState(
    [] as PieChartDataItem[]
  );

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  });

  const init = async () => {
    const sustainabilityImpactChartData = [
      {
        label: "Re-use",
        value: metrics?.reuseWeight ?? 0,
      },
      {
        label: "Recycle",
        value: metrics?.recycleWeight ?? 0,
      },
      // {
      //   label: "Other",
      //   value:
      //     metrics?.totalWeight -
      //     (metrics?.reuseWeight ?? 0) -
      //     (metrics?.recycleWeight ?? 0),
      // },
    ];

    const circularEconomyChartData = [
      {
        label: "Re-use",
        value: metrics?.totalReuse ?? 0,
      },
      {
        label: "Recycle",
        value: metrics?.totalRecycle ?? 0,
      },
      // {
      //   label: "Other",
      //   value:
      //     metrics?.totalInventory -
      //     (metrics?.totalReuse ?? 0) -
      //     (metrics?.totalRecycle ?? 0),
      // },
    ];

    // Count
    const {
      totalLaptop,
      totalDesktop,
      totalServer,
      totalAccessPoint,
      totalSwitch,
    } = metrics?.inventoryBreakdown ?? {};
    const totalNetworking = (totalAccessPoint ?? 0) + (totalSwitch ?? 0);
    const remaining =
      metrics?.totalInventory -
      totalLaptop -
      totalDesktop -
      totalServer -
      totalNetworking;

    // Weight
    const {
      laptopWeight,
      desktopWeight,
      serverWeight,
      accessPointWeight,
      switchWeight,
    } = metrics?.inventoryBreakdown ?? {};
    const totalNetworkingWeight =
      (accessPointWeight ?? 0) + (switchWeight ?? 0);
    const remainingWeight =
      metrics?.totalWeight -
      (laptopWeight ?? 0) -
      (desktopWeight ?? 0) -
      (serverWeight ?? 0) -
      totalNetworkingWeight;

    const ewasteBreakdownChartData = [
      {
        label: "Laptop",
        value: totalLaptop,
      },
      {
        label: "Desktop",
        value: totalDesktop,
      },
      {
        label: "Server",
        value: totalServer,
      },
      {
        label: "Networking",
        value: totalNetworking,
      },
      {
        label: "General E-waste",
        value: remaining,
      },
    ];

    const ewasteBreakdownWeightChartData = [
      {
        label: "Laptop",
        value: laptopWeight ?? 0,
      },
      {
        label: "Desktop",
        value: desktopWeight ?? 0,
      },
      {
        label: "Server",
        value: serverWeight ?? 0,
      },
      {
        label: "Networking",
        value: totalNetworkingWeight,
      },
      {
        label: "General E-waste",
        value: remainingWeight,
      },
    ];

    setCircularEconomy(circularEconomyChartData);
    setSustainabilityImpact(sustainabilityImpactChartData);
    setEwasteBreakdown(ewasteBreakdownChartData);
    setEwasteBreakdownWeight(ewasteBreakdownWeightChartData);
  };

  useEffect(() => {
    init();
  }, [metrics]);

  return (
    <BaseCard
      w="100%"
      gap="16px"
      justifyContent="center"
      alignItems="center"
      py="26px"
      flexDir="column"
    >
      <Grid
        gridTemplateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(2,1fr)"}
        rowGap={isMobile ? "30px" : "0px"}
      >
        <PieChartComponent
          title="💻 E-Waste Breakdown"
          data={ewasteBreakdown}
          width={280}
          height={280}
          titleClassname="text-2xl font-semibold mb-2"
        />
        <PieChartComponent
          title="🌀 Circular Economy"
          data={circularEconomy}
          width={280}
          height={280}
          titleClassname="text-2xl font-semibold mb-2"
        />
        <PieChartComponent
          unit="kg"
          title="💻 E-Waste Breakdown (kg)"
          data={ewasteBreakdownWeight}
          width={280}
          height={280}
          titleClassname="text-2xl font-semibold mb-2"
        />
        <PieChartComponent
          unit="kg"
          title="♻️ Sustainability Impact"
          data={sustainabilityImpact}
          width={280}
          height={280}
          titleClassname="text-2xl font-semibold mb-2"
        />
      </Grid>

      <TermsAndConditions />
    </BaseCard>
  );
};

const JobProgressPlaceholder = ({ date }) => {
  const jobPlaceholder = {
    status: "PICKUP",
    totalInventory: "-",
    startDate: convertIsoTime(date),
    contactEmail: "-",
  };

  return <JobProgress job={jobPlaceholder} defaultOpen={false} />;
};
