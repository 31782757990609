import { useEffect, useState } from "react";
import { Switch, FormControl, FormLabel, HStack } from "@chakra-ui/react";

export const ToggleInput = (props: Props) => {
  const { leftLabel, rightLabel, onChange, style, name, defaultChecked } =
    props;

  const [checked, setChecked] = useState(defaultChecked);

  const handleChange = (e: any) => {
    const obj = {
      ...e,
      target: {
        ...e.target,
        value: e.target.checked,
        name: name ?? e.target.name,
      },
    };

    setChecked(e.target.checked);
    onChange(obj);
  };

  return (
    <FormControl
      w="fit-content"
      color="black"
      display="flex"
      {...style}
      alignItems="center"
    >
      <FormLabel
        color={checked ? undefined : "#000080"}
        _hover={{ cursor: "pointer" }}
        hidden={!leftLabel}
        mb="0"
      >
        {leftLabel}
      </FormLabel>
      <HStack>
        <Switch
          size="sm"
          colorScheme="green"
          name={name}
          defaultChecked={defaultChecked}
          onChange={handleChange}
        />
        <FormLabel
          hidden={!rightLabel}
          mb="0"
          color={checked ? "green" : undefined}
          _hover={{ cursor: "pointer", color: "black" }}
        >
          {rightLabel}
        </FormLabel>
      </HStack>
    </FormControl>
  );
};

interface Props {
  leftLabel?: string;
  rightLabel?: string;
  style?: any;
  name?: string;
  defaultChecked?: boolean;
  onChange: (e: any) => void;
}
