import React, { useEffect, useState } from "react";
import styles from "../../styles/Home.module.css";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { Sidebar } from "../app/sidebar/Sidebar";
import Header from "../app/header";
import { SidebarMobile } from "../app/mobile/SidebarMobile";
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const AppLayout: React.FC<any> = (props: any) => {
  const { title, metaAttrs, children } = props;
  const { sidebarCollapsed } = useSelector(
    (state: RootState) => state.userProfile
  );
  const [defaultHeader, setDefaultHeader] = useState(true);

  const [sidebarWidth, setSidebarWidth] = React.useState(sidebarCollapsed ? 80 : 260);
  const handleToggle = (open: boolean) => {
    setSidebarWidth(open ? 260 : 80);
  };

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  });

  const location = useLocation();

  useEffect(() => {
    if (window.location.pathname === "/app/account") {
      setDefaultHeader(false);
    } else {
      setDefaultHeader(true);
    }
  }, [location]);

  return (
    <Box bg="white">
      {!isMobile ? <Sidebar onToggle={handleToggle} /> : <SidebarMobile />}
      <Box pl={{ base: 0, md: `${sidebarCollapsed ? 80 : 260}px` }}>
        <main id="main" className={styles.main}>
          {children}
        </main>
      </Box>
    </Box>
  );
};

export default AppLayout;
