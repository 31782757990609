import { Box } from "@chakra-ui/react";
import { FlexRow } from "../../../shared/FlexRow";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import PopoverCustom from "../../../shared/PopoverCustom";

export const JobDetailNotes = ({ job }) => {
  if (!job.notes) return <></>;

  return (
    <FlexRow>
      <PopoverCustom
        width="300px"
        trigger={
          <Box
            maxWidth="200px"
            display="flex"
            alignItems="center"
            height="80%"
            px={3}
            py={1}
            _hover={{
              bgColor: "gray.200",
            }}
            rounded={{ base: "md", md: "xl" }}
            cursor="pointer"
            overflow="hidden"
          >
            <Box
              fontWeight="500"
              maxWidth="100%"
              flex="1"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {job.notes}
            </Box>
          </Box>
        }
      >
        <Box
          dangerouslySetInnerHTML={{
            __html: job.notes.replace(/\n/g, "<br />"),
          }}
        ></Box>
      </PopoverCustom>

      <SpeakerNotesIcon style={{ color: "#457662" }} />
    </FlexRow>
  );
};