import { Box, Container, Flex } from "@chakra-ui/react";
import React from "react";
import { LoadingComponent } from "../shared/Loading";
import { FlexCol } from "../shared/FlexCol";

const BaseContainer: React.FC<any> = (props: any) => {
  const { children, styles } = props;

  return (
    <Box
      width={{ md: "100%" }}
      maxWidth={{ base: "100%", md: "640px" }}
      mt={{ base: "0", md: "24px" }}
      mx={{ base: "16px", md: "auto" }}
      p="24px"
      borderRadius="8px"
      bg="white"
      filter="drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))"
      {...styles}
    >
      <Box>{children}</Box>
    </Box>
  );
};

export const BaseCard = ({ loading = undefined, children, ...props }: any) => {
  return (
    <Flex
      flexDir="row"
      h="full"
      w="full"
      boxShadow="0px 8px 16px 0px #00000033"
      p="12px"
      gap="12px"
      bg="white"
      border="1px solid lightGray"
      borderRadius="10px"
      {...props}
    >
      {loading ? (
        <FlexCol center w="full" h="full">
          <LoadingComponent w={8} h={8} thickness="4px" color="brand.green" />
          <h2>Loading...</h2>
        </FlexCol>
      ) : (
        children
      )}
    </Flex>
  );
};

export default BaseContainer;
