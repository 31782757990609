import { CompanyModel } from "../../models/company.model";
import { UserModel } from "../../models/user.model";
import { BaseService } from "../base.service";

export class BranchUserService extends BaseService {
  constructor() {
    super();
  }

  async getUsers(id: string) {
    const resp = await this.client.get(
      `/company/branch/user/list?companyId=${id}`
    );
    return resp.data;
  }

  async inviteUser(user: { email: string; companyId: string }) {
    const resp = await this.client.post(
      `/company/branch/user/manage/invite`,
      user
    );
    return resp.data;
  }

  async revokeInvite(user: { email: string; companyId: string }) {
    await this.client.post(`/company/branch/user/manage/invite/revoke`, user);
  }

  async deactivate(user: { email: string; companyId: string }): Promise<any[]> {
    const resp = await this.client.post(
      `/company/branch/user/manage/deactivate`,
      user
    );
    return resp.data;
  }

  async activate(user: { email: string; companyId: string }): Promise<any[]> {
    const resp = await this.client.post(
      `/company/branch/user/manage/activate`,
      user
    );
    return resp.data;
  }

  async getInvitedList(companyId: string) {
    const resp = await this.client.get(
      `/company/branch/user/manage/invite/list?companyId=${companyId}`
    );
    return resp.data;
  }

  async updateRole(user: {
    email: string;
    roleName: string;
    companyId: string;
  }): Promise<any[]> {
    const resp = await this.client.put(
      `/company/branch/user/update/role`,
      user
    );

    return resp.data;
  }
}

export const branchUserService = new BranchUserService();