import { createSlice } from "@reduxjs/toolkit";

export interface Company {
  name: string;
  photoUri?: string;
  isHeadquarters?: boolean;
  rebateRequired?: boolean;
  country?: string;
  sortColumn?: { accessor: string; direction: 0 | 1 };
}

export const initialState: Company = {
  name: "",
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    update: (state, params) => {
      const f = params.payload as Company;

      state.name = f.name;
      state.photoUri = f.photoUri;
      state.country = f.country;
      state.isHeadquarters = f.isHeadquarters;
      state.sortColumn = f.sortColumn;
      state.rebateRequired = f.rebateRequired;
    },
    updatePhotoUri: (state, params) => {
      state.photoUri = params.payload as string;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update, updatePhotoUri } = companySlice.actions;

export default companySlice.reducer;
