import { DownloadIcon } from "@chakra-ui/icons";
import { Flex, Box, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { ReportService } from "../../../service/job/report.service";
import { useState } from "react";
import { errorToast } from "../../../constants/toast.constants";
import { StatusTag } from "../../shared/tag/StatusTag";
import { JobStatusTag } from "../../shared/tag/JobStatusTag";
import { FlexRow } from "../../shared/FlexRow";
import { WasteImpactTag } from "../../shared/tag/WasteImpactTag";

export const JobDetailHeader = (props: Props) => {
  const reportService = new ReportService();

  const { job, inventoryCount } = props;
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const handleAcknowledgement = async () => {
    setLoading(true);
    await reportService
      .downloadAcknowledgementReport(job.id)
      .then((resp) => {
        const blob = new Blob([new Uint8Array(resp.data.data)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `acknowledgement-report-${job.id}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(() => {
        toast(errorToast("Failed to download pick-up report"));
      })
      .finally(() => setLoading(false));
  };

  const handleItad = async () => {
    setLoading(true);
    await reportService
      .downloadItadReport(job.id)
      .then((resp) => {
        const blob = new Blob([new Uint8Array(resp.data.data)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `greenguard-report-${job.id}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(() => {
        toast(errorToast("Failed to download GreenGuard™ report"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {/* <FlexRow>
        {!!job.totalImpact && <WasteImpactTag />}
      </FlexRow> */}
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        gap="16px"
        mb="12px"
      >
        <FlexRow mb={2} gap="16px">
          <FlexRow gap="6px" fontSize="3xl" fontWeight="bold">
            <Box>JOB-{job.jobNo}</Box>
          </FlexRow>

          <FlexRow>
            <JobStatusTag status={job.status} />
            {job.rebateRequired && (
              <StatusTag excludeDot type="info">
                Rebate required
              </StatusTag>
            )}
          </FlexRow>
        </FlexRow>

        <Box>
          <ButtonCustom
            hidden={!inventoryCount}
            neutral
            style={{ marginRight: "6px" }}
            onClick={handleAcknowledgement}
            disabled={loading}
          >
            Pick-up report (.xlsx)
            <DownloadIcon ml="6px" />
          </ButtonCustom>
          <ButtonCustom
            hidden={!inventoryCount}
            onClick={handleItad}
            disabled={loading}
          >
            GreenGuard™ Report
            <DownloadIcon ml="6px" />
          </ButtonCustom>
        </Box>
      </Flex>
    </>
  );
};

interface Props {
  job: any;
  inventoryCount?: number;
}
