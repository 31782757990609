import { FlexCol } from "./FlexCol";

export const TermsAndConditions = ({ ...props }) => {
    return (
      <FlexCol
        flexDir="column"
        alignItems="center"
        fontSize="12px"
        textColor="gray.500"
        textAlign="center"
        px="26px"
        mt="26px"
        {...props}
      >
        These sustainability statistics provide a clear and transparent view of
        your e-waste management, supporting compliance and climate disclosure
        reporting. Zolo is certified to ISO 27001:2013 for Information Security
        Management, ISO 14001:2015 for Environmental Management Systems, AS/NZS
        5377:2013 for Responsible Recycling and Treatment of Electrical and
        Electronic Equipment (E-Waste), and ISO 9001:2015 for Quality Management
        Systems. Our processes are independently audited by Compass Assurance
        Services to ensure alignment with globally recognised standards.{" "}
        <span className="text-brand-green-300 font-semibold cursor-pointer underline w-fit" onClick={() => window.open("https://cas.com.au/", "_blank")}>Learn
        More</span>
      </FlexCol>
    );
  };
  
  