import { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { jobService } from "../../../service/job/job.service";
import { useParams, useSearchParams } from "react-router-dom";
import { downloadCsvToMachine } from "../../../util/file.util";
import { useAppDispatch, useAppSelector } from "../../../util/hooks.util";
import { Loading } from "../../../components/shared/Loading";
import { updateJobFilter as inventoryUpdate } from "../../../store/slice/Account/inventory.slice";
import { InventoryBulkFilter } from "../../../models/filter/inventory-bulk.filter";
import { InventoryModel } from "../../../models/inventory.model";
import { inventoryService } from "../../../service/inventory/inventory.service";
import { JobDetailHeader } from "../../../components/app/job/job-detail.header";
import { JobDetailContent } from "../../../components/app/job/job-detail-content.header";
import { InventoryTable } from "../../../components/app/inventory/inventory.table";

export const JobDetail = ({}) => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const jobStore = useAppSelector((state) => state.inventory);

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [pageWidth, setPageWidth] = useState("100%");
  const [activeInventory, setActiveInventory] = useState(null);
  const [job, setJob] = useState({} as any);
  const [inventory, setInventory] = useState({
    data: [],
    totalItems: 0,
    totalPage: 0,
  } as { data: InventoryModel[]; totalPage: number; totalItems: number });
  const [pageNum, setPageNum] = useState(1);
  const [checkedIds, setCheckedIds] = useState([] as string[]);

  useEffect(() => {
    if (searchParams.get("inventory_id")) {
      setActiveInventory(searchParams.get("inventory_id"));
    }
  }, [searchParams.get("inventory_id")]);

  const init = async () => {
    setLoading(true);
    await fetchJob()
      .then(async (job) => {
        setJob(job);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchJob = async () => {
    const prms = new Promise((resolve, reject) =>
      jobService.get(id).then((job) => {
        setJob(job);
        resolve(job);
      })
    );

    return await prms;
  };

  const fetchInventory = async (
    page?: number,
    filter?: InventoryBulkFilter,
    sort?: any
  ) => {
    const PAGE_SIZE = 50;

    setLoading(true);
    await inventoryService
      .getList(
        {
          jobId: id,
          ids: activeInventory ? [activeInventory] : undefined,
          ...filter,
        },
        undefined,
        page,
        PAGE_SIZE,
        sort ?? jobStore.jobSortColumn
      )
      .then((inventory) => {
        setInventory(inventory);
      })
      .finally(() => setLoading(false));
  };

  const handleInventorySort = async (sort: {
    accessor: string;
    direction: 0 | 1;
  }) => {
    setPageNum(1);
    await fetchInventory(1, { jobId: id, ...jobStore.jobFilter }, sort);
  };

  const handleExport = async () => {
    const obj = {
      jobId: id,
      ids: !!checkedIds?.length ? checkedIds : undefined,
      ...jobStore.jobFilter,
    };

    setLoading(true);
    await inventoryService
      .exportToCsv(obj)
      .then((csvData) => {
        downloadCsvToMachine(csvData, "inventory-export.csv");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    init();
  }, [id, activeInventory]);

  useEffect(() => {
    fetchInventory();
  }, [activeInventory]);

  return (
    <>
      <Loading loading={loading} />
      <Box w={pageWidth} maxW="1900px">
        <Flex flexDirection="column">
          <Box mb="24px" px={4} py={6} width="100%">
            <JobDetailHeader job={job} inventoryCount={inventory?.totalItems} />
            {!loading && (
              <JobDetailContent job={job} inventory={inventory?.data} />
            )}
          </Box>
        </Flex>

        {!loading && (
          <InventoryTable
            key={inventory.totalItems}
            inventory={inventory.data}
            isJobDetail={true}
            onDelete={(id: string) =>
              setInventory({
                data: inventory.data.filter((i) => i.id !== id),
                totalPage: inventory.totalPage,
                totalItems: inventory.totalItems,
              })
            }
            onBulkDelete={async () =>
              await fetchInventory(pageNum, jobStore.jobFilter)
            }
            onCheckChange={(data) =>
              setCheckedIds(data.filter((x) => x.isChecked).map((x) => x.id))
            }
            onEdit={async () =>
              await fetchInventory(pageNum, jobStore.jobFilter)
            }
            onFilterChange={async (filter: InventoryBulkFilter) => {
              setPageNum(1);
              dispatch(inventoryUpdate(filter));
              await fetchInventory(1, filter);
            }}
            onPageWidthChange={(width) => setPageWidth(width)}
            onSort={handleInventorySort}
          />
        )}
      </Box>
    </>
  );
};
