import { JobStatus } from "../../../models/enum/job-status.enum";
import { StatusTag } from "./StatusTag";

export const JobStatusTag = ({ status }: { status: string }) => {
  const statusMap = {
    PENDING: "warning",
    IN_PROGRESS: "info",
    COLLECTION: "info",
    COMPLETE: "success",
  };

  return (
    <StatusTag
      type={statusMap[status as keyof typeof statusMap] as any}
    >
      {JobStatus[status]}
    </StatusTag>
  );
};
