import { DownloadIcon } from "@chakra-ui/icons";
import { Flex, Box, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { ReportService } from "../../../service/job/report.service";
import { useState } from "react";
import { errorToast } from "../../../constants/toast.constants";
import { StatusTag } from "../../shared/tag/StatusTag";
import { JobStatusTag } from "../../shared/tag/JobStatusTag";
import { WasteImpactTag } from "../../shared/tag/WasteImpactTag";
import { convertIsoTime } from "../../../util/date.util";
import PopoverCustom from "../../shared/PopoverCustom";
import { FlexRow } from "../../shared/FlexRow";
import { FlexCol } from "../../shared/FlexCol";
import { TermsAndConditions } from "../../shared/TermsConditions";
import { JobDetailAttachment } from "./parts/job-detail-attachment";
import { JobDetailNotes } from "./parts/job-detail-notes";
import { JobDetailMetrics } from "./parts/job-detail-metrics";

interface Props {
  job: any;
  inventory: any;
}

export const JobDetailHeader = (props: Props) => {
  const reportService = new ReportService();

  const { job, inventory } = props;
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const handleAcknowledgement = async () => {
    setLoading(true);
    await reportService
      .downloadAcknowledgementReport(job.id)
      .then((resp) => {
        const blob = new Blob([new Uint8Array(resp.data.data)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `acknowledgement-report-${job.id}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(() => {
        toast(errorToast("Failed to download pick-up report"));
      })
      .finally(() => setLoading(false));
  };

  const handleItad = async () => {
    setLoading(true);
    await reportService
      .downloadItadReport(job.id)
      .then((resp) => {
        const blob = new Blob([new Uint8Array(resp.data.data)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `greenguard-report-${job.id}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(() => {
        toast(errorToast("Failed to download GreenGuard™ report"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {/* <FlexRow>
        {!!job.totalImpact && <WasteImpactTag />}
      </FlexRow> */}
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        gap="16px"
        mb="12px"
      >
        <FlexRow mb={2} gap="16px">
          <FlexRow gap="6px" fontSize="3xl" fontWeight="bold">
            <Box>JOB-{job.jobNo}</Box>
          </FlexRow>

          <FlexRow>
            <JobStatusTag status={job.status} />
            {job.rebateRequired && (
              <StatusTag excludeDot type="info">
                Rebate required
              </StatusTag>
            )}
          </FlexRow>
        </FlexRow>

        <Box>
          <ButtonCustom
            hidden={!inventory?.totalItems}
            neutral
            style={{ marginRight: "6px" }}
            onClick={handleAcknowledgement}
            disabled={loading}
          >
            Pick-up report (.xlsx)
            <DownloadIcon ml="6px" />
          </ButtonCustom>
          <ButtonCustom
            hidden={!inventory?.totalItems}
            onClick={handleItad}
            disabled={loading}
          >
            GreenGuard™ Report
            <DownloadIcon ml="6px" />
          </ButtonCustom>
        </Box>
      </Flex>

      <Flex
        pos="relative"
        flexDir="column"
        gap="4px"
        justifyContent="space-between"
        w="full"
      >
        <Flex fontWeight="600" color="gray" flexDir="row" gap="8px" w="full">
          <FlexCol>
            <FlexRow between w="full">
              <FlexRow gap="4px">
                📍 {job?.companyAddressName}
              </FlexRow>
            </FlexRow>

            <FlexRow between>
              <FlexRow gap="4px">
                🗓️ Collection Date: {convertIsoTime(job?.startDate)}
              </FlexRow>
            </FlexRow>

            <Box w="fit-content">
              <FlexRow
                link
                onClick={() =>
                  window.open(
                    `/app/company/${job?.companyId}`,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              >
                🤝🏼 <Box>{job.companyName}</Box>
                {/* <SwitchAccessShortcutIcon /> */}
              </FlexRow>
            </Box>

            <JobDetailMetrics job={job} />
          </FlexCol>

          <Flex flexDir="column" alignItems="end">
            <JobDetailAttachment job={job} />
            <JobDetailNotes job={job} />
            <DisclaimerSection />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const DisclaimerSection = () => {
  return (
    <Flex>
      <PopoverCustom
        width="500px"
        trigger={
          <Box
            maxWidth="200px"
            display="flex"
            alignItems="center"
            height="80%"
            px={3}
            py={1}
            _hover={{
              bgColor: "gray.200",
            }}
            rounded={{ base: "md", md: "xl" }}
            cursor="pointer"
            overflow="hidden"
          >
            <Box
              fontWeight="500"
              maxWidth="100%"
              flex="1"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              Click for the Nitty Gritty
            </Box>
          </Box>
        }
      >
        <TermsAndConditions fontWeight="500" textAlign="left" alignItems="flex-start" mb="16px" />
      </PopoverCustom>
    </Flex>
  );
};

