import {
  Box,
  Flex,
  useDisclosure,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Image } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useNavigate } from "react-router-dom";
import { AvatarCustom } from "../../form/avatar.custom";
import { Sidebar } from "../sidebar/Sidebar";

export const SidebarMobile: React.FC<any> = (props: any) => {
  const navigate = useNavigate();
  const [bgColor, setBgColor] = useState("transparent");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  const { email, displayName, photoUri } = useSelector(
    (state: RootState) => state.userProfile
  );

  const checkScroll = () => {
    if (window.scrollY === 0) {
      setBgColor("transparent");
    } else {
      setBgColor("brand.yellow.200");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);

    // Clean up function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  return (
    <Flex
      pos="fixed"
      zIndex="99"
      top="-1px"
      left="0"
      w="100vw"
      h="60px"
      flexDir="row"
      justifyContent="space-between"
      alignItems="center"
      bg={bgColor}
      px="26px"
    >
      <Box>
        <Image
          src="/logo.svg"
          alt="Zolo Logo"
          height="20px"
          cursor="pointer"
          onClick={() => navigate("/app/dashboard")}
        />
      </Box>
      <Box ref={btnRef} onClick={onOpen}>
        <AvatarCustom
          src={photoUri}
          widthxheight="48px"
          border="3px solid white"
        />
      </Box>
      <MenuSidebar
        isOpen={isOpen}
        btnRef={btnRef}
        onClose={onClose}
        onToggle={onClose}
      />
    </Flex>
  );
};

const MenuSidebar = ({ isOpen, btnRef, onClose, onToggle }) => {
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      size="xs"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent px="18px" pb="150px" w="240px" bg="brand.green.200">
        <DrawerCloseButton
          style={{ zIndex: "99", color: "white", top: "23px" }}
        />
        <Sidebar
          style={{ border: "none" }}
          onClick={onClose}
          onToggle={onToggle}
        />
      </DrawerContent>
    </Drawer>
  );
};
